import App from "./App.vue";
import { createApp } from "vue";
import router from "./router";
import store from "./store";

/** 按需引入ElementPlus start */
// import {
//   ElForm,
//   ElInput,
//   ElButton,
//   ElCheckbox,
//   ElContainer,
//   ElAside,
//   ElMenu,
//   ElMenuItemGroup,
//   ElMenuItem,
//   ElSubMenu,
//   ElCard,
//   ElPopover,
//   ElTag
// } from "element-plus";
/** 按需引入ElementPlus组件 end */

const app = createApp(App);

// 引入element-plus
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/display.css"; // 引入响应式相关样式
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
app.use(ElementPlus, {
  locale: zhCn
});

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

/**自定义样式文件 start*/
import "./assets/style/reset.scss"; //引入HTML标签重定义样式文件
import "./assets/style/common.scss"; // 引入公共自定义样式
import "./assets/style/iconfont.scss"; //iconfont
import "./assets/style/media.scss"; // 引入媒体查询样式
/**自定义样式文件 end*/

/**引入ElementPlus样式文件 start*/
import "element-plus/dist/index.css";
import "./assets/style/element-variables.scss";
/**引入ElementPlus样式文件 start*/

//定义组件ElementPlus组件统一大小
app.config.globalProperties.$ELEMENT = { size: "medium", zIndex: 3000 };

app.use(store);
app.use(router);
// app
//   .use(ElForm)
//   .use(ElInput)
//   .use(ElButton)
//   .use(ElCheckbox)
//   .use(ElContainer)
//   .use(ElAside)
//   .use(ElMenu)
//   .use(ElMenuItemGroup)
//   .use(ElMenuItem)
//   .use(ElSubMenu)
//   .use(ElCard)
//   .use(ElPopover)
//   .use(ElTag);

app.mount("#app");
