import { createRouter, createWebHistory } from "vue-router";

const routes = [
  // {
  //   path: "/hello",
  //   component: () => import("@/components/HelloWorld")
  // },
  // 首页
  {
    path: "/",
    name: "WebsiteIndex",
    component: () =>
      import(/* webpackChunkName: "index" */ "../views/Index.vue")
  },
  // {
  //   path: "/index",
  //   name: "Website",
  //   component: () =>
  //     import(/* webpackChunkName: "index" */ "../views/Index.vue")
  // },
  {
    path: "/wait",
    name: "BaseWait",
    component: () =>
      import(/* webpackChunkName: "wait" */ "../components/BaseWait.vue"),
    meta: {}
  },
  // 咨询与购买
  {
    path: "/pricing",
    name: "Pricing",
    component: () =>
      import(/* webpackChunkName: "textLibrary" */ "../views/Pricing.vue")
  }
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.afterEach(() => {
  window.scrollTo(0, 0); //切换路由时，滚动条回到最顶部
});

export default router;
